@use '@sats-group/ui-lib/tokens/spacing';
@use '@sats-group/ui-lib/tokens/light';

.boot-camps {
  background: light.$background-secondary-default;

  &__title {
    color: light.$on-surface-primary-default;
  }

  &__header {
    padding-top: spacing.$l;
    padding-bottom: spacing.$m;
  }

  &__top-section {
    display: flex;
    flex-direction: column;
    gap: spacing.$s;
  }

  &__result {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap-reverse;
    gap: spacing.$s;
  }
}
